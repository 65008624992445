import { QUERY, DEVICE } from 'constants/dimension';
import { SELF_LEARNING_CLASS, PROBLEM_SOLVING_CLASS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { CLASSIFICATION } from 'constants/index';
import { HomeTitle } from 'components';
import SVG from 'components/molecules/Icon/svg';
import React, { useEffect, useState, useMemo } from 'react';
import { getPublished as getPublishedApi } from 'services/api/courses';
import { useClass } from 'store/class';
import { useServerTime } from 'store/serverTime';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useResponsive } from 'utils/hooks/useResponsive';
import CalendarModal from './CalendarModal';
import ClassRoom from './ClassRoom';
import {
  UiExtraLearningContent,
  UiButton,
  UiTitleWrapper,
  UiPageMan,
  UiPageManContainer,
  UiCustomIcon,
} from './ExtraLearningContent.style';

import SessionModal from './SessionModal';

const selfStudyBackground = '#FAFAFC';

export const ExtraLearningContent = () => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const { setAlert } = useAlert();

  const [isLoadingSelfStudySessions, setIsLoadingSelfStudySessions] = useState(true);
  const [isLoadingProblemSolvingSessions, setIsLoadingProblemSolvingSessions] = useState(true);

  const [selfLearningData, setSelfLearningData] = useState([]);
  const [problemSolvingData, setProblemSolvingData] = useState([]);

  const [selectSession, setSelectedSession] = useState([]);
  const [isOpenClassModal, setIsOpenClassModal] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const [{ myOrganization }] = useUser();
  const [{ myClasses }] = useClass();
  const { grades } = myClasses?.dataInfo;
  const classGrades = useMemo(() => grades && grades.map((grade) => grade.padStart(3, 'G0')), [grades]);
  const hasInterestingClassSetting =
    myOrganization?.organization?.organizationExtraLearningSetting.interestingClassSetting ===
    ORGANIZATION_SETTINGS_VALUE.ENABLED;

  // 點擊打開教室說明
  const clickSessionCard = async (session) => {
    setSelectedSession(session);
    setIsOpenClassModal(true);
  };

  const fetchOpenCourse = async () => {
    setIsLoadingSelfStudySessions(true);
    setIsLoadingProblemSolvingSessions(true);
    const startAt = serverTimestamp;
    const params = { startAt };
    const selfStudyClassification = [CLASSIFICATION.SELF_LEARNING_CLASS];
    hasInterestingClassSetting && selfStudyClassification.push(CLASSIFICATION.SELF_GAMING_LEARNING_CLASS);

    try {
      // 取得 自習教室 資料
      const selfStudyResponse = await getPublishedApi({
        ...params,
        classification: selfStudyClassification,
      });
      // 取得 解題教室 資料
      const problemSolveResponse = await getPublishedApi({
        ...params,
        classification: [CLASSIFICATION.PROBLEM_SOLVING_CLASS],
      });

      if (selfStudyResponse?.isSuccess && problemSolveResponse?.isSuccess) {
        setSelfLearningData(selfStudyResponse.data);
        setProblemSolvingData(problemSolveResponse.data);
        setIsLoadingSelfStudySessions(false);
        setIsLoadingProblemSolvingSessions(false);
      } else {
        setAlert('取得公開課資料失敗', 'error');
      }
    } catch (error) {
      error.toString().includes('TypeError: Failed to fetch')
        ? setAlert(error, 'error', 502)
        : setAlert(error, 'error');
    }
  };

  // 取得公開課列表
  useEffect(() => {
    if (!serverTimestamp || !grades || !myOrganization?.organization) return;
    fetchOpenCourse();
  }, [serverTimestamp, grades, myOrganization]);

  return (
    <UiExtraLearningContent>
      <UiTitleWrapper isTabletL={isTabletL}>
        <HomeTitle title={'課外學習'} hint="以下為今日開放教室，點擊課程行事曆可查看更多" />
        <UiButton
          buttonColor="standardSecondary"
          color="#121232"
          variant="outlined"
          onClick={() => setIsOpenCalendar(true)}
        >
          <UiCustomIcon component={SVG['CalendarCheck']} />
          課程行事曆
        </UiButton>
        {!isTabletL && (
          <UiPageManContainer>
            <UiPageMan />
          </UiPageManContainer>
        )}
      </UiTitleWrapper>

      <ClassRoom
        background={selfStudyBackground}
        isLoading={isLoadingSelfStudySessions}
        sessions={selfLearningData}
        title={SELF_LEARNING_CLASS}
        classGrades={classGrades}
        onClickCard={clickSessionCard}
      />

      <ClassRoom
        title={PROBLEM_SOLVING_CLASS}
        sessions={problemSolvingData}
        isLoading={isLoadingProblemSolvingSessions}
        classGrades={classGrades}
        onClickCard={clickSessionCard}
      />

      {/* 教室資訊 modal */}
      <SessionModal session={selectSession} isOpen={isOpenClassModal} onClose={() => setIsOpenClassModal(false)} />

      {/* 課程行事曆 modal */}
      <CalendarModal
        classGrades={classGrades}
        onClose={() => setIsOpenCalendar(false)}
        open={isOpenCalendar}
        hasInterestingClassSetting={hasInterestingClassSetting}
      />
    </UiExtraLearningContent>
  );
};
