import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Loading } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTeacher } from 'store/teacher';
import { useUser } from 'store/user';
import { UiTeachersContainer } from './Teachers.style';

const MuiIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckedIcon = <CheckBoxIcon fontSize="small" />;

export const Teachers = ({ examTeachers, isLoading, setIsLoading, onChange, formData, setFormData }) => {
  const [{ allTeachers, multiTeacher }, { getAllTeachers, getMultiClassTeacher }] = useTeacher();
  const [{ myOrganizationAllTeacher }] = useUser();
  const [teachers, setTeachers] = useState([]);
  const [classTeacher, setClassTeacher] = useState([]);

  const teacherListTest = (item, index) => {
    const list = formData.createClass.map((classObj) => {
      const { value } = classObj;
      const filterClass = myOrganizationAllTeacher.groups.find((item) => item.id === value).hosts;

      return filterClass;
    });
    if (multiTeacher?.data[item.value]?.length === list?.length) {
      return list[index];
    } else {
      const listItem = multiTeacher?.data[item.value]?.map((teacher) => {
        return teacher.id;
      });
      const uniqItem = _.intersection(listItem, list[0]);

      return uniqItem;
    }
  };

  const { dataInfo } = useSelector((state) => state.class.myClasses);

  /* 格式化 API 資料 - 取得老師資訊 */
  const formatAllTeachers = () => {
    const isEdit = examTeachers.length > 0;
    let newTeachers = allTeachers.data.map(({ id, nickname }) => ({
      checked: false,
      id,
      nickname,
      title: `${nickname} (ID:${id})`,
    }));
    if (isEdit) {
      examTeachers.forEach((teacher) => {
        const index = newTeachers.findIndex(({ id }) => id === teacher);
        newTeachers[index].checked = true;
      });
    }
    setTeachers(newTeachers);
  };

  /* 打 API，取得所有老師 */
  const fetchAllTeachers = async () => {
    setIsLoading(true);
    await getAllTeachers();
    setIsLoading(false);
  };

  const fetchMultiTeacher = async (formData) => {
    setIsLoading(true);
    for (let item of formData) {
      await getMultiClassTeacher(item.value);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllTeachers();
    fetchMultiTeacher(formData.createClass);
  }, []);

  useEffect(() => {
    if (!allTeachers.total) return;
    formatAllTeachers();
  }, [allTeachers.total, examTeachers]);

  const handleClassChange = (_, value) => {
    onChange(value);
    setClassTeacher(value);
  };

  // 其他班級的老師選擇邏輯
  const handleTeacherChange = (index) => (_, value) => {
    let newArr = [...formData.createClass];
    newArr[index].teacherList = value;
    setFormData((prev) => ({
      ...prev,
      createClass: newArr,
    }));
  };

  const multiClassTeacherOption = (option, item) => {
    const result = multiTeacher?.data[item.value]?.find((element) => element.id === option);
    return `${result?.nickname}(ID:${result?.id})`;
  };
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <h3>{dataInfo.name}</h3>
      <Autocomplete
        multiple
        label="選擇老師"
        required
        options={teachers}
        disableCloseOnSelect
        noOptionsText="查無測驗"
        getOptionLabel={(option) => option.title}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={MuiIcon}
              checkedIcon={CheckedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              color="primary"
            />
            {option.title}
          </React.Fragment>
        )}
        limitTags={1}
        renderInput={(params) => <TextField {...params} label="選擇老師" variant="outlined" required />}
        value={classTeacher}
        onChange={handleClassChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionSelected={(option, value) => option.id === value.id}
      />
      <UiTeachersContainer>
        {formData.createClass?.map((item, index) => {
          return (
            <div key="index">
              <h3>{item.name}</h3>
              <Autocomplete
                multiple
                label="選擇老師"
                required
                options={teacherListTest(item, index)}
                disableCloseOnSelect
                getOptionLabel={(option) => multiClassTeacherOption(option, item)}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={MuiIcon}
                      checkedIcon={CheckedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {multiClassTeacherOption(option, item)}
                  </React.Fragment>
                )}
                limitTags={1}
                renderInput={(params) => <TextField {...params} label="選擇老師" variant="outlined" required />}
                onChange={handleTeacherChange(index)}
                value={formData.createClass[index]?.teacherList}
                key={teacherListTest(index)}
              />
            </div>
          );
        })}
      </UiTeachersContainer>
    </>
  );
};

Teachers.propTypes = {
  examTeachers: PropTypes.array,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  setIsLoading: PropTypes.func,
  formData: PropTypes.instanceOf(Array),
  setFormData: PropTypes.func,
};
