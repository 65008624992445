import {
  TextField as MatTextField,
  Button as MatButton,
  Radio as MatRadio,
  RadioGroup as MatRadioGroup,
  FormControlLabel as MatFormControlLabel,
} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Icon, DateTimePicker } from 'components';
import { Select as CustomSelect } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useUser } from 'store/user';

import { SELECT_ALL } from '../../HomeOnlineExam';
import { UiFormBox, UiTabBox, UiTabItem, UiDelButton, UiFormScrollBox } from './BasicData.style';

const MuiIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckedIcon = <CheckBoxIcon fontSize="small" />;

const CREATE_TYPE = {
  NOW: 'now',
  RESERVE: 'reserve'
};

const initClassInputField = {
  value: '',
  name: ''
};


export const BasicData = ({
  createExamType,
  disabledTab,
  examName,
  examPeriod,
  startAt,
  onChange,
  onChangeCreateType,
  setFormData,
  formData,
  setSelectedOptions,
  selectedOptions,
  gradeSetting,
  setGradeSetting
}) => {
  const { classId } = useParams();
  const [{ myOrganizationAllTeacher: { groups: orgGroups, userNames: orgUsers } }, { getOrganizationAllTeacher }] = useUser();
  const [currentClass, setCurrentClass] = useState();

  const {
    dataInfo
  } = useSelector(state => state.class.myClasses);

  // 本班所有學生 {id, name}
  const currentStudents = useMemo(() => {
    if (!orgUsers.length) return [];
    const students = dataInfo.users.map(userId => {
      const user = orgUsers.find(orgUser => orgUser.userId === userId);
      if (user) {
        return {
          id: userId,
          name: user.name,
        };
      } else {
        return null;
      }
    });
    students.unshift({ id: SELECT_ALL, name: SELECT_ALL });
    return students;
  }, [orgUsers]);

  // 班級list需要先篩選掉目前的班級
  const currentOrganizationList = (orgGroups.filter((item) => item.id !== classId).map((classItem) => ({
    value: classItem.id,
    name: classItem.name
  })));

  const handleCreateNewClass = () => {
    setFormData({
      createClass: [
        ...formData.createClass,
        initClassInputField
      ]
    });
  };


  const handleDeleteNewClass = (filterItem) => {
    setFormData({
      createClass: formData.createClass.filter((_, index) => index !== filterItem)
    });
  };

  // 本班學生下拉選單
  const handleOnChange = (list) => (_, value, reason) => {
    // 選到的選項
    const option = value.find(element => !selectedOptions.some(option => option.id === element.id));
    // 選到「全班」的選項
    const filterAll = selectedOptions.find(element => !value.some(option => option.id === element.id));
    console.log('current student option', option);

    if (reason === 'select-option' && (option?.id === SELECT_ALL || value.length === (dataInfo.users).length)) {
      setSelectedOptions(currentStudents);
    } else if (reason === 'remove-option' && filterAll?.id === SELECT_ALL) {
      setSelectedOptions([]);
    } else if (option?.id !== SELECT_ALL) {
      setSelectedOptions(value.filter((item) => item.id !== SELECT_ALL));
    } else {
      setSelectedOptions(value);
    }
  };

  // 其他班級的下拉選單
  const handleStudentChange = (index, allList) => (event, value, reason) => {
    const studentList = formData.createClass[index].studentList;

    // 去掉重複選項
    const uniqValue = _.uniqWith(value, _.isEqual);
    // 有無重複
    const hasDuplicate = value.length > uniqValue.length;

    const option = studentList.length === 0 ? value[0] : value.find(element => !studentList.some(item => item.id === element.id));
    const filterAll = studentList.find(element => !uniqValue.some(option => option.id === element.id));

    const handleSetStudentList = (listData, selectAll = false) => {
      setFormData(prevFormData => {
        const updatedCreateClass = [...prevFormData.createClass];
        updatedCreateClass[index] = {
          ...updatedCreateClass[index],
          studentList: listData,
          selectAll: selectAll
        };
        return {
          ...prevFormData,
          createClass: updatedCreateClass
        };
      });
    };

    if (hasDuplicate) {
      const newOption = value[value.length - 1];
      const newValue = newOption.id === SELECT_ALL ? [] : uniqValue.filter(({ id }) => id !== newOption.id);
      handleSetStudentList(newValue);
    } else if (reason === 'select-option' && (option?.id === SELECT_ALL || uniqValue.length === studentOptions(index).length)) {
      handleSetStudentList(
        [
          { id: SELECT_ALL, name: SELECT_ALL },
          ...allList
        ]
      );
    } else if (reason === 'remove-option' && filterAll?.id === SELECT_ALL) {
      handleSetStudentList([]);
    } else if (option?.id !== SELECT_ALL) {
      handleSetStudentList(
        uniqValue.filter((item) => item.id !== SELECT_ALL)
      );
    } else {
      handleSetStudentList(uniqValue);
    }
  };

  const handleCreateClass = (value, index) => {
    const OrganizationName = currentOrganizationList.find(item => item.value === value)?.name;
    setCurrentClass(OrganizationName);
    formData.createClass[index].studentList = [];
    setFormData(prevFormData => {
      const updatedClass = [...prevFormData.createClass];
      updatedClass[index] = {
        value,
        name: OrganizationName,
        // 重新選擇要先將學生清空
        studentList: [],
      };
      return { ...prevFormData, createClass: updatedClass };
    });
  };

  const studentOptions = useCallback((index) => {
    const currentClassUserIds = orgGroups.find((item) => item.id === formData.createClass[index]?.value)?.users || [];
    const currentClassUsers = currentClassUserIds.map(userId => {
      const user = orgUsers.find(orgUser => orgUser.userId === userId);
      return {
        id: user.userId,
        name: user.name,
      };
    });

    let allStudents = [];
    formData.createClass.forEach((item) => {
      return allStudents.push(...item.studentList);
    });

    const cleanCurrentClass = _.difference(_.difference(currentClassUsers, selectedOptions), allStudents);

    return cleanCurrentClass;
  }, [currentClass, selectedOptions]);

  const handleGradeSettingChange = (e) => {
    setGradeSetting(e.target.value === 'true' ? true : false);
  };

  useEffect(() => {
    getOrganizationAllTeacher();
  }, []);

  return (
    <>
      {!disabledTab &&
        <UiTabBox>
          <UiTabItem
            className={createExamType === CREATE_TYPE.NOW ? 'active' : ''}
            onClick={() => onChangeCreateType(CREATE_TYPE.NOW)}
          >
            <Icon.Svg name="Rocket" color={createExamType === CREATE_TYPE.NOW ? '#fff' : '#8B90A0'} />
            <span>立即派卷</span>
          </UiTabItem>
          <UiTabItem
            className={createExamType === CREATE_TYPE.RESERVE ? 'active' : ''}
            onClick={() => onChangeCreateType(CREATE_TYPE.RESERVE)}
          >
            <Icon.Svg name="Calendar" color={createExamType === CREATE_TYPE.RESERVE ? '#fff' : '#8B90A0'} />
            <span>預約派卷</span>
          </UiTabItem>
        </UiTabBox>}
      <UiFormBox position={createExamType === CREATE_TYPE.NOW ? '24%' : '72%'}>
        <UiFormScrollBox>
          <MatTextField
            label="測驗名稱"
            variant="outlined"
            value={examName || ''}
            onChange={e => onChange(e.target.value, 'examName')}
            fullWidth
            required
            style={{ marginTop: '5px' }}
          />
          <MatTextField
            label="作答時間(分鐘)"
            variant="outlined"
            type="number"
            style={{ marginTop: '24px' }}
            value={examPeriod || null}
            onChange={e => onChange(e.target.value, 'examPeriod')}
            fullWidth
            required
          />
          {createExamType === CREATE_TYPE.RESERVE &&
            <DateTimePicker
              label="開始時間"
              style={{ marginTop: '24px' }}
              value={startAt || null}
              onChange={value => onChange(value, 'startAt')}
              fullWidth
              required
            />}
          <>
            <div style={{ marginTop: '24px' }}>
              <h3 style={{ margin: '0px' }}>
                公布成績設定
              </h3>
            </div>
            <MatRadioGroup value={gradeSetting} onChange={handleGradeSettingChange}>
              <MatFormControlLabel
                value={true}
                control={<MatRadio />}
                label={<span style={{ fontSize: '14px' }}>{'於測驗結束時公布成績'}</span>}
                color="pink"
                style={{ marginTop: '16px', width: '200px' }}
              />
              <MatFormControlLabel
                value={false}
                control={<MatRadio />}
                label={<span style={{ fontSize: '14px' }}>{'於學生交卷時公布成績'}</span>}
                style={{ marginTop: '12px', width: '200px' }}
              />
            </MatRadioGroup>
            <div style={{ marginTop: '24px' }}>
              <h3 style={{ margin: '0px' }}>
                派卷對象
              </h3>
              <h4 style={{ marginTop: '16px', marginBottom: '0px' }}>
                {dataInfo.name}
              </h4>
            </div>
            <Autocomplete
              multiple
              label="學生"
              style={{ marginTop: '16px' }}
              required
              fullWidth
              options={currentStudents}
              getOptionLabel={option => option.name}
              disableCloseOnSelect
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={MuiIcon}
                      checkedIcon={CheckedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color='primary'
                    />
                    {option.id === SELECT_ALL ? option.name : `${option.name}(ID:${option.id})`}
                  </React.Fragment>
                );
              }
              }
              limitTags={1}
              renderInput={(params) => (
                <TextField {...params} label="學生" variant='outlined' required />
              )}
              value={selectedOptions}
              onChange={handleOnChange(dataInfo.users)}
              {...(selectedOptions.length === (dataInfo.users).length + 1 ? { renderTags: () => '全班' } : {})}
            />

            {
              formData.createClass.map((item, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <hr style={{ margin: '16px 0', backgroundColor: '#E4E7EC' }} />
                  <CustomSelect
                    label='班級'
                    variant='outlined'
                    isOutline={false}
                    options={currentOrganizationList}
                    value={formData.createClass[index].value}
                    id='createClass'
                    onChangeHandler={(values) => handleCreateClass(values, index)}
                  />
                  <Autocomplete
                    multiple
                    fullWidth
                    style={{ margin: '16px 0 12px 0' }}
                    label="學生"
                    required
                    options={
                      studentOptions(index).length > 0
                        ? [
                          { id: SELECT_ALL, name: SELECT_ALL },
                          ...studentOptions(index)
                        ] : []
                    }
                    getOptionLabel={option => option.name}
                    disableCloseOnSelect
                    renderOption={(option) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={MuiIcon}
                            checkedIcon={CheckedIcon}
                            style={{ marginRight: 8 }}
                            checked={formData.createClass[index].studentList.some(student => student.id === option.id)}
                            color='primary'
                          />
                          {option.id === SELECT_ALL ? option.name : `${option.name}(ID:${option.id})`}
                        </React.Fragment>
                      );
                    }
                    }
                    limitTags={1}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="學生"
                          variant='outlined'
                          required
                        />
                      );
                    }}
                    value={formData.createClass[index].studentList}
                    key={studentOptions(index)}
                    onChange={handleStudentChange(index, studentOptions(index))}
                    {...(formData.createClass[index]?.studentList?.find((item) => item.id === SELECT_ALL) ? { renderTags: () => SELECT_ALL } : {})}
                  />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'end'
                  }}>
                    <UiDelButton
                      onClick={() => handleDeleteNewClass(index)}
                    >
                      移除
                    </UiDelButton>

                  </div>
                </div>
              ))
            }
            {
              formData.createClass.length > 0 && (
                <div style={{ marginTop: '12px' }}>
                  <hr style={{ backgroundColor: '#E4E7EC' }} />
                </div>
              )
            }
            <MatButton
              variant="outlined"
              onClick={() => handleCreateNewClass()}
              style={{
                borderRadius: '4px',
                marginTop: '16px',
                fontSize: '14px',
                alignSelf: 'flex-start'
              }}
            >
              <AddIcon />新增派卷班級
            </MatButton>
          </>
        </UiFormScrollBox>
      </UiFormBox >
    </>
  );
};

BasicData.defaultProps = {
  disabledTab: false,
};

BasicData.propTypes = {
  createExamType: PropTypes.string,
  disabledTab: PropTypes.bool,
  examName: PropTypes.string,
  examPeriod: PropTypes.number,
  startAt: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCreateType: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.instanceOf(Array),
  setSelectedOptions: PropTypes.func,
  selectedOptions: PropTypes.instanceOf(Array),
  gradeSetting: PropTypes.bool,
  setGradeSetting: PropTypes.func,
};
