import { CLASSIFICATION } from 'constants/index';
import styled from 'styled-components';

import problemSolvingBook from '../../../molecules/Icon/svg/problemSolvingBook.svg';
import processing from '../../../molecules/Icon/svg/processing.svg';
import robot from '../../../molecules/Icon/svg/robot.svg';
import selfLearningBook from '../../../molecules/Icon/svg/selfLearningBook.svg';

const bookType = {
  [CLASSIFICATION.SELF_LEARNING_CLASS]: selfLearningBook,
  [CLASSIFICATION.SELF_GAMING_LEARNING_CLASS]: robot,
  [CLASSIFICATION.PROBLEM_SOLVING_CLASS]: problemSolvingBook,
};

export const UiSessionCard = styled.div`
  width: 320px;
  background: #fff;
  border: 1px solid #f2f4f8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 20px 14px 20px;
  cursor: pointer;
`;

export const UiSessionHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 11px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e7ec;
`;

export const UiBook = styled.img.attrs(({ src }) => ({
  src: bookType[src],
}))``;

export const UiSessionTitle = styled.div`
  font-family: Noto Sans CJK TC;
  font-weight: 500;
  font-size: 20px;
  color: #2f3649;
  margin-left: 16px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const UiHeaderDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiSessionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const UiSessionTime = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #8b90a0;
`;

export const UiSessionProcessing = styled.img.attrs({
  src: processing,
})``;
