import { CLASSIFICATION } from 'constants/index';
import { Fade as MuiFade } from '@material-ui/core';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getPublished as getPublishedApi } from 'services/api/courses';
import { getDurationText, getEduSubjectsText, getGradesText, getIsIncludesClassGrades } from 'utils/helper';
import Calendar from '../Calendar';
import SessionModal from '../SessionModal';

import {
  UiMuiCalendarModal,
  UiModalContent,
  UiHeader,
  UiTitle,
  UiClose,
  UiCategoryWrapper,
  UiCategory,
  UiCategoryColor,
  UiCategoryName,
  UiBody,
} from './CalendarModal.style';

const TITLE = '課程行事曆';
const CATEGORIES = [
  {
    name: '自習教室',
    color: '#EC7963',
  },
  {
    name: '解題教室',
    color: '#FACD61',
  },
];

/* 自習教室顏色表 */
const SELF_LEARNING = {
  backgroundColor: '#FEF2F0',
  borderColor: '#EC7963',
  textColor: '#D26855',
};

/* 解題教室顏色表 */
const PROBLEM_SOLVING = {
  backgroundColor: '#FFFAEF',
  borderColor: '#FACD61',
  textColor: '#BC8531',
};

export const CalendarModal = ({ classGrades, onClose, open, hasInterestingClassSetting }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // 課外學習 API 資料
  const [openCoursesOri, setOpenCoursesOri] = useState([]);
  const [session, setSession] = useState({});

  /**
   * fullCalendar 顯示用資料
   * {
   *  backgroundColor: "#FEF2F0"
   *  borderColor: "#EC7963"
   *  start: 1684375246000
   *  end: 1684376746000
   *  textColor: "#D26855"
   *  title: "abcdef123"
   * }
   */
  const events = useMemo(() => {
    if (!openCoursesOri.length) return;
    const eventMap = new Map();

    // format API 資料
    openCoursesOri.forEach((course) => {
      const { classification, endAt, grades, title, startAt } = course;
      const isIncludesClassGrades = getIsIncludesClassGrades(classGrades, grades);
      if (!isIncludesClassGrades) return;
      const key = `${startAt}-${endAt}`;
      const value = {
        end: endAt,
        title,
        start: startAt,
      };

      // 設定 calendar event 顏色
      const setColor = (category) => {
        value.backgroundColor = category.backgroundColor;
        value.borderColor = category.borderColor;
        value.textColor = category.textColor;
      };
      if (
        classification === CLASSIFICATION.SELF_LEARNING_CLASS ||
        classification === CLASSIFICATION.SELF_GAMING_LEARNING_CLASS
      )
        setColor(SELF_LEARNING);
      else if (classification === CLASSIFICATION.PROBLEM_SOLVING_CLASS) setColor(PROBLEM_SOLVING);

      // 將 value 設定至 map 對應的 key
      eventMap.has(key) ? eventMap.get(key).push(value) : eventMap.set(key, [value]);
    });

    return [...eventMap.values()].flat();
  }, [openCoursesOri]);

  /**
   * click calendar event
   * @param {object} info calendar event info
   */
  const eventClick = (info) => {
    setIsOpen(true);
    const { title } = info.event;
    const index = openCoursesOri.findIndex((openCourse) => openCourse.title === title);
    const durationText = getDurationText(openCoursesOri[index]);
    const eduSubjectsText = getEduSubjectsText(openCoursesOri[index]);
    const gradesText = getGradesText(openCoursesOri[index]);
    const newSession = { ...openCoursesOri[index], durationText, eduSubjectsText, gradesText };
    setSession(newSession);
  };

  /**
   * 打 API，取得會員公開課
   * @param {object} calendarView calendar view 物件
   */
  const fetchOpenCourses = async (calendarView) => {
    setIsLoading(true);
    const { currentEnd, currentStart } = calendarView;
    const endAt = new Date(currentEnd).getTime();
    const startAt = new Date(currentStart).getTime();
    const classification = [CLASSIFICATION.PROBLEM_SOLVING_CLASS, CLASSIFICATION.SELF_LEARNING_CLASS];
    hasInterestingClassSetting && classification.push(CLASSIFICATION.SELF_GAMING_LEARNING_CLASS);
    const params = { endAt, startAt, classification };
    const response = await getPublishedApi(params);
    const { status } = response;
    if (status === 'success') {
      const { data } = response;
      setOpenCoursesOri(data);
    }
    setIsLoading(false);
  };

  /**
   * 設定 calendar events
   * @param {object} calendarView calendar view 物件
   */
  const setEvents = (calendarView) => {
    fetchOpenCourses(calendarView);
  };

  return (
    <>
      <UiMuiCalendarModal disablePortal disableEnforceFocus disableAutoFocus open={open} onClose={onClose}>
        <MuiFade in={open}>
          <UiModalContent>
            <UiHeader>
              <UiTitle>{TITLE}</UiTitle>
              <UiClose>
                <Icon haveBg={false} icolor="#A1A4B1" name="close" onClick={onClose} />
              </UiClose>
              <UiCategoryWrapper>
                {CATEGORIES.map(({ name, color }, index) => (
                  <UiCategory key={name}>
                    <UiCategoryColor background={color} />
                    <UiCategoryName>{name}</UiCategoryName>
                  </UiCategory>
                ))}
              </UiCategoryWrapper>
            </UiHeader>

            <UiBody>
              <Calendar events={events} eventClick={eventClick} loading={isLoading} setEvents={setEvents} />
            </UiBody>
          </UiModalContent>
        </MuiFade>
      </UiMuiCalendarModal>

      {/* 教室資訊 modal */}
      <SessionModal isOpen={isOpen} onClose={() => setIsOpen(false)} session={session} />
    </>
  );
};

CalendarModal.propTypes = {
  classGrades: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  hasInterestingClassSetting: PropTypes.bool,
};
