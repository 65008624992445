import { Tab, Box } from '@material-ui/core';
import forest from 'assets/images/forest.svg';
import { Table, Button, Loading, Select, EmptyPrompt, HomeTitle } from 'components';
import { stringify } from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  getTeacherAssignedVideoRecord,
  getStudentVideoRecord,
  getStudentAllVideoRecord,
} from 'services/api/home/video';
import { useClass } from 'store/class';
import { useTeacher } from 'store/teacher';
import { useUser } from 'store/user';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import { isCounty } from 'utils/validation';
import { UiHomeStudentVideoTable, UiMatTabs } from './HomeStudentVideoTable.style';

const VIDEO_BANK_BUTTON_TEXT = isCounty ? '前往 OneClass 影音網' : '前往南一影音網';
const VIDEO_BANK_URL = isCounty
  ? process.env.REACT_APP_ONE_CLASS_VIDEO_BANK_DOMAIN
  : process.env.REACT_APP_VIDEO_BANK_DOMAIN;

/**
 * 學生影音列表
 */

const ViewedTable = ({ videoRecord, teacherId, classId, studentId }) => {
  const { organizationId } = useParams();
  const [{ nowPage, rowsPage }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 50,
  });

  const schema = {
    item: {
      name: '品項',
      defaultValue: '-',
    },
    name: {
      name: '影片名稱',
      defaultValue: '-',
    },
    time: {
      name: '影片時長',
      defaultValue: '--',
    },
  };

  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  const ActionComponents = ({ params }) => {
    const goToVideoBank = () => {
      const queryString = stringify({
        teacher: teacherId,
        course: classId,
        student: studentId,
        no: params.no,
        organizationId,
      });
      window.open(`${VIDEO_BANK_URL}?${queryString}`);
    };
    return (
      <Button buttonColor="new" onClick={goToVideoBank}>
        影片播放
      </Button>
    );
  };

  const tableData = useMemo(() => {
    return paginationFilter(videoRecord, nowPage, rowsPage);
  }, [videoRecord, nowPage, rowsPage]);

  return (
    <>
      <Table
        data={tableData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={videoRecord.length || 10}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
      {tableData.length === 0 && <EmptyPrompt img={forest} message={'老師尚未指派影片'} messageColor={'#8B90A0'} />}
    </>
  );
};

const AssignedTable = ({ videoRecord, classId, teacherId, studentId }) => {
  const { organizationId } = useParams();
  const [{ data, nowPage, rowsPage }, setState] = useSetState({
    data: [],
    nowPage: 0,
    rowsPage: 10,
  });

  const schema = {
    item: {
      name: '品項',
      defaultValue: '-',
    },
    name: {
      name: '影片名稱',
      defaultValue: '--',
    },
    time: {
      name: '影片時長',
      defaultValue: '00:00',
    },
    isViewed: {
      name: '狀態',
      default: '未觀看',
    },
  };

  const isViewedShowText = [
    {
      id: 'isViewed',
      state: {
        false: '未觀看',
        true: '觀看完成',
      },
    },
  ];

  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  const ActionComponents = ({ params }) => {
    const goToVideoBank = () => {
      const queryString = stringify({
        teacher: teacherId,
        course: classId,
        student: studentId,
        no: params.no,
        organizationId,
      });
      window.open(`${VIDEO_BANK_URL}?${queryString}`);
    };
    return (
      <Button buttonColor="new" onClick={goToVideoBank}>
        影片播放
      </Button>
    );
  };

  const fetchAssignVideoList = async ({ classId, teacherId }) => {
    const res = await getTeacherAssignedVideoRecord({ classId, teacherId });
    if (res.isSuccess) {
      setState({ data: Object.values(res.data) });
    }
  };

  useEffect(() => {
    fetchAssignVideoList({ classId, teacherId });
  }, [classId, teacherId]);

  const videoList = useMemo(() => {
    const viewedVideoIds = videoRecord.map((item) => item.no);
    return data.map((item) => ({ ...item, isViewed: viewedVideoIds.includes(item.no) }));
  }, [data]);

  const tableData = useMemo(() => {
    return paginationFilter(videoList, nowPage, rowsPage);
  }, [videoList, nowPage, rowsPage]);

  return (
    <>
      <Table
        data={tableData}
        schema={schema}
        stateShowText={isViewedShowText}
        changePage_Rows={changePage_Rows}
        totalPage={data.length}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
      {tableData.length === 0 && <EmptyPrompt img={forest} message={'尚無任何觀看紀錄'} messageColor={'#8B90A0'} />}
    </>
  );
};

export const HomeStudentVideoTable = () => {
  const isVisible = usePageVisibility();
  const { organizationId } = useParams();
  const [{ profile }] = useUser();
  const { id: userId } = profile;
  const [
    {
      myClasses: {
        dataInfo: { id: classId },
      },
    },
  ] = useClass();
  const [
    {
      teachers: { data },
    },
    { getTeachers },
  ] = useTeacher();

  const [{ tabIndex, teacherId, recordTeacherId, videoRecord }, setState] = useSetState({
    tabIndex: 0,
    teacherId: '',
    recordTeacherId: 'all',
    videoRecord: [],
  });

  const getTabIndexHandler = (e, tabIndex) => {
    setState({
      tabIndex,
    });
  };

  const fetchStudentVideoRecord = async () => {
    const payload = {
      classId,
      studentId: userId,
      teacherId: recordTeacherId,
    };
    if (recordTeacherId === 'all') delete payload.teacherId;
    const res =
      recordTeacherId === 'all' ? await getStudentAllVideoRecord(payload) : await getStudentVideoRecord(payload);
    if (res.isSuccess) {
      const nextData = res.data.sort((a, b) => b.addAt - a.addAt);
      setState({ videoRecord: nextData });
    }
  };

  useEffect(() => {
    if (!recordTeacherId) return;
    fetchStudentVideoRecord();
  }, [recordTeacherId, isVisible]);

  useEffect(() => {
    getTeachers({
      nowPage: 0,
      rowsPage: 100,
    });
  }, []);

  const teachers = useMemo(() => {
    return data.map((teacher) => ({ name: teacher.nickname, value: teacher.id })) || [];
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      setState({ teacherId: data[0].id });
    }
  }, [data]);

  const goVideoBK = () => {
    const queryString = stringify({
      course: classId,
      student: profile.id,
      organizationId,
    });
    window.open(`${VIDEO_BANK_URL}?${queryString}`);
  };

  return (
    <UiHomeStudentVideoTable>
      <HomeTitle title={'影音學習'} controlMessage={'影音學習以下有兩個項目可使用左右鍵選擇'} />
      <UiMatTabs value={tabIndex} onChange={getTabIndexHandler}>
        <Tab label="老師指派影片" />
        <Tab label="學生觀看紀錄" />
      </UiMatTabs>
      <Box width="100%" mb={2} display="flex" alignItems="center" justifyContent="space-between">
        {tabIndex === 0 ? (
          <Select
            name="teacher"
            label="老師"
            value={teacherId}
            options={teachers}
            onChangeHandler={(teacherId) => setState({ teacherId })}
          />
        ) : (
          <Select
            name="teacher"
            label="老師"
            value={recordTeacherId}
            options={[{ name: '全部', value: 'all' }].concat(teachers)}
            onChangeHandler={(teacherId) => setState({ recordTeacherId: teacherId })}
          />
        )}
        <Button onClick={() => goVideoBK()}>{VIDEO_BANK_BUTTON_TEXT}</Button>
      </Box>
      {teacherId ? (
        <>
          {tabIndex === 0 && (
            <AssignedTable videoRecord={videoRecord} classId={classId} studentId={userId} teacherId={teacherId} />
          )}
          {tabIndex === 1 && (
            <ViewedTable videoRecord={videoRecord} classId={classId} studentId={userId} teacherId={recordTeacherId} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </UiHomeStudentVideoTable>
  );
};
