import { COMPANY_TITLE, OUTPUT_TYPE_NAME, OUTPUT_TYPE_NAME_KEY } from 'constants/index';
import { CURRENT_CLASS_STUDENT, SINGLE_STUDENT, MULTI_CLASS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { Tab, TextField as MatTextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import imageLinkAlt from 'assets/svg/link-alt.svg';
import imageTestCloud from 'assets/svg/test-cloud.svg';
import imageTestReport from 'assets/svg/test-report.svg';
import imageTestReview from 'assets/svg/test-review.svg';
import {
  HomeTitle,
  BsModal,
  HomeExamPaperProprietary,
  HomeExamPaperOrganization,
  HomeExamPaperPackage,
  HomeOnlineExamTableV2,
} from 'components';
import { LABEL_COLOR } from 'components/atoms/Label/Label';
import {
  setOnePaperLoginCookieByServiceRoot,
  setOnePaperLoginCookieByOrganizationId,
  setOnePaperAllowEduSubjectsCookieBySubjects,
  formatEducationSubjects,
} from 'helper/onePaper';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWrongExamsCount } from 'services/api/home/exam';
import { createOrganizationGroupExam, createCrossClassExam } from 'services/api/home/onlineExam';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { UiflexBox } from 'styles';
import { getJWTValue } from 'utils/helper';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { isCounty } from 'utils/validation';
import ExamModal from './ExamModal';
import {
  UiHomeOnlineExam,
  UiMatTabs,
  UiActionsBox,
  UiToggleButtonGroup,
  UiEntryBanner,
  UiMainScreenBox,
  UiHomeExamPaperBox,
} from './HomeOnlineExam.style';

export const SELECT_ALL = '全班';
// <Label/> 為「線上測驗」要求的類型：線上測驗、速測
export const ONLINE_EXAM_REQUEST_TYPES = [
  OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.ONLINE],
  OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.QUICK],
];
// <Label color />: 線上測驗 -> info，速測 -> warning
export const ONLINE_EXAM_LABEL_COLOR = {
  [OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.ONLINE]]: LABEL_COLOR.info,
  [OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES]]: LABEL_COLOR.warning,
};

/**
 * 線上測驗頁面for Teacher
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;

export const HomeOnlineExam = ({ token, isTeacher }) => {
  const [
    {
      tabIndex,
      searchValue,
      isPromptModal,
      // subjectPlaceholder,
      placeholder,
      // eduSubjectMap,
      isOpenModal,
      paperId,
      examName,
      examPeriod,
      examTeachers,
      startAt,
      isLoading,
      eduSubjectName,
      wrongExamsCount,
    },
    setState,
  ] = useSetState({
    tabIndex: 1,
    searchValue: null,
    isPromptModal: false,
    // subjectPlaceholder:'全部科目',
    placeholder: '搜尋試卷名稱',
    // eduSubjectMap : [],
    isOpenModal: false,
    paperId: '',
    examName: '',
    examPeriod: null,
    examTeachers: [],
    startAt: null,
    isLoading: false,
    eduSubjectName: null,
    wrongExamsCount: 0,
    className: '',
  });

  const [formData, setFormData] = useState({
    createClass: [],
    class: [],
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [gradeSetting, setGradeSetting] = useState(true);
  const [exams, setExams] = useState();
  const [examPaperMode, setExamPaperMode] = useState('proprietary');
  const [filterData, setFilterData] = useState({
    name: null,
  });

  const { classId, organizationId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile, myOrganization }] = useUser();
  const [
    {
      myClasses: { dataInfo },
    },
  ] = useClass();
  const { organizationRole: userRole, name: classNumber } = dataInfo;
  const isEnabledOrganizationSharedPapersSetting =
    myOrganization.organization?.organizationSharedPapersSetting === ORGANIZATION_SETTINGS_VALUE.ENABLED;
  const isEnabledPaperPackageSetting =
    myOrganization.organization?.paperPackageSetting === ORGANIZATION_SETTINGS_VALUE.ENABLED;
  const queryStringForOneData = useMemo(() => {
    const obj = { classId, institutionId: organizationId };
    const userId = getJWTValue('uid');
    if (!isTeacher && userId) {
      obj.userId = userId;
    }
    return queryString.stringify(obj);
  }, [classId, organizationId, isTeacher]);

  const oneDataEntry = useMemo(() => {
    if (!exams) return [];
    const hasExams = !!exams.length;
    const studentEntries = [
      {
        title: '自我測驗',
        description: '挑選不同知識點難易度，自行練習',
        link: 'paper',
        icon: imageTestCloud,
        buttonText: '前往',
      },
      {
        title: '個人分析報表',
        description: '查看個人的測驗結果分析',
        link: '/student',
        icon: imageTestReport,
        buttonText: hasExams ? '前往' : '尚無報表',
      },
      {
        title: '複習錯題',
        description: '自動派發錯題卷複習，精準消除弱點',
        link: '/review',
        icon: imageTestReview,
        buttonText: hasExams ? '前往' : '尚無錯題',
      },
    ];
    const teacherEntries = [
      {
        title: '題庫出卷',
        description: '豐富多元題目，自由組卷',
        link: 'paper',
        icon: imageTestCloud,
        buttonText: '前往',
      },
      {
        title: '成績總表',
        description: '查看班級成績單、個人/班級分析報表',
        link: '/transcript',
        icon: imageTestReport,
        buttonText: hasExams ? '前往' : '尚無報表',
      },
      {
        title: '複習錯題',
        description: '練習老師派發的錯題卷，精準消除弱點',
        link: '/review',
        icon: imageTestReview,
        buttonText: hasExams ? '前往' : '尚無錯題',
      },
    ];
    return isTeacher ? teacherEntries : studentEntries;
  }, [exams]);

  const urlFilter = (link) => {
    let newLink = `${process.env.REACT_APP_ONEDATA_DOMAIN}${link}?${queryStringForOneData}`;

    const linkMap = {
      paper: process.env.REACT_APP_ONEPAPER_DOMAIN,
    };

    if (link in linkMap) {
      newLink = linkMap[link];
    }

    return newLink;
  };

  const getTabIndexHandler = (e, tabIndex) => {
    setState({
      tabIndex,
    });
  };

  const onCreateExam = ({ eduSubjectName, paperId, name }) => {
    setState({
      eduSubjectName,
      paperId,
      examName: name,
      isOpenModal: true,
    });
  };

  // 沒有學生的彈窗控制
  const onPrompt = () => {
    setState({
      isPromptModal: !isPromptModal,
    });
  };

  const closeModal = () => {
    setState({
      isOpenModal: false,
      paperId: '',
      examPeriod: null,
      examName: '',
      examTeachers: [],
      startAt: '',
    });
    setFormData({
      createClass: [],
      class: [],
    });
    setSelectedOptions([]);
  };

  // input change
  const onSearchChange = (event) => {
    setState({
      searchValue: event.target.value,
    });
  };

  const clickSearchIcon = () => {
    setFilterData((prev) => ({
      ...prev,
      name: searchValue,
    }));
  };

  const onSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      const name = event.target.value;
      setFilterData((prev) => ({
        ...prev,
        name,
      }));
    }
  };

  const onModalChange = (value, key) => {
    setState({
      [key]: value,
    });
  };

  const createExam = async (type) => {
    const filterAll = selectedOptions.filter((item) => item.id !== SELECT_ALL);

    /**
     * 本班學生一筆 且 本班學生人數不為1 = 'student'
     * 本班學生兩筆以上 或是 有跨班級 = 'multi'
     * 本班學生全選 且 沒有跨班級 = 'group'
     */
    console.log(filterAll, selectedOptions, 'cjecl');
    const validGroupType = () => {
      if (formData.createClass.length === 0) {
        if (filterAll.length === dataInfo.users.length) {
          return CURRENT_CLASS_STUDENT;
        } else if (filterAll.length !== dataInfo.users.length && selectedOptions.length > 1) {
          return MULTI_CLASS;
        } else {
          return SINGLE_STUDENT;
        }
      } else {
        return MULTI_CLASS;
      }
    };

    const payload = {
      eduSubjectName,
      paperId,
      organizationId,
      groupId: classId,
      period: parseInt(examPeriod),
      name: examName,
      groupType: validGroupType(),
      waitingEnd: gradeSetting,
    };

    const groupList = formData?.createClass?.map((item) => {
      const filterAll = item?.studentList?.filter((groupItem) => groupItem.id !== SELECT_ALL);
      return {
        groupId: item.value,
        testers: filterAll?.map((item) => item.id),
        markers: item.teacherList.map((item) => item),
      };
    });

    const currentClassList = {
      groupId: classId,
      testers: filterAll?.map((item) => item.id),
      markers: examTeachers,
    };

    const multiClassList = [...groupList, currentClassList];

    const multiClassPayload = {
      examId: paperId,
      examGroups: multiClassList,
      organizationId,
    };

    if (type === 'reserve') {
      payload.startAt = startAt;
      payload.endAt = startAt + examPeriod * 60 * 1000;
    }
    if (filterAll.length === 1 && validGroupType() === SINGLE_STUDENT) {
      payload.tester = filterAll[0].id;
    }
    if (validGroupType() !== MULTI_CLASS) {
      payload.markers = examTeachers;
    }
    if (validGroupType() === SINGLE_STUDENT) {
      payload.tester = selectedOptions.map(option => option.id);
    }
    if (!examName) setAlert('請輸入測驗名稱', 'error');
    if (!examPeriod) setAlert('請輸入測驗時間', 'error');
    if (!examTeachers.length) setAlert('請勾選批改老師', 'error');
    if (!examName || !examPeriod || !examTeachers.length) return;
    if (type === 'reserve' && !startAt) return;
    setState({
      isLoading: true,
    });
    const { isSuccess, data } = await createOrganizationGroupExam(payload);
    const multiClass =
      validGroupType() === MULTI_CLASS && isSuccess
        ? await createCrossClassExam({
          ...multiClassPayload,
          examId: data.id,
        })
        : { isSuccess: isSuccess };

    if (isSuccess && multiClass?.isSuccess) {
      setAlert('派卷成功', 'success');
      setState({
        paperId: '',
        examPeriod: null,
        examName: '',
        examTeachers: [],
        startAt: '',
        isOpenModal: false,
      });
      setFormData({
        createClass: [],
        class: [],
      });
      setSelectedOptions([]);
      setGradeSetting(true);
    } else {
      setAlert('派卷失敗', 'error');
    }
    setState({
      isLoading: false,
    });
  };

  const changeExamPaperMode = (event, newExamPaperMode) => {
    if (!newExamPaperMode) return;
    setExamPaperMode(newExamPaperMode);
    setState({ searchValue: '' });
    setFilterData((prev) => ({
      ...prev,
      name: null,
    }));
  };

  useEffect(() => {
    setOnePaperLoginCookieByServiceRoot();
    setOnePaperLoginCookieByOrganizationId(organizationId);
    const educationSettings = myOrganization?.userProfile?.educationSettings;
    const educationSubjects = formatEducationSubjects(educationSettings);
    setOnePaperAllowEduSubjectsCookieBySubjects(educationSubjects);
  }, [myOrganization?.userProfile]);

  useEffect(() => {
    // 取得錯題數量（學生）
    // 避免還沒取得身份造成誤判（isTeacher 等於 undefined 的狀況）
    if (isTeacher !== false) return;
    getWrongExamsCount({
      institutionId: organizationId,
      userName: profile.id,
      service: 'oneLink',
    }).then((res) => {
      setState({ wrongExamsCount: res?.data?.total || 0 });
    });
  }, [isTeacher]);

  return (
    <UiHomeOnlineExam>
      <HomeTitle title={'線上測驗'} />
      {userRole && (isCompany || isCounty) && (
        <UiEntryBanner>
          {oneDataEntry.map((entry) => (
            <div key={entry.title} className="banner__item">
              <img className="banner__icon" alt="" src={entry.icon} />
              <div className="banner__text">
                <div className="banner__header">
                  <div className="banner__title">{entry.title}</div>
                  {entry.buttonText === '前往' ? (
                    // 因為 OneData 需要，所以不加 noreferrer
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a className="banner__link" href={urlFilter(entry.link)} target="_blank" rel="noopener">
                      <img src={imageLinkAlt} alt="" />
                      <span>{entry.buttonText}</span>
                      {entry.title === '複習錯題' && !isTeacher && wrongExamsCount > 0 && (
                        <div className="banner__wrongExamsCount">{wrongExamsCount}</div>
                      )}
                    </a>
                  ) : (
                    <div className="banner__linkDisabled">
                      <span>{entry.buttonText}</span>
                    </div>
                  )}
                </div>
                <span className="banner__description">{entry.description}</span>
              </div>
            </div>
          ))}
        </UiEntryBanner>
      )}
      {isTeacher && (
        <UiMatTabs value={tabIndex} onChange={getTabIndexHandler}>
          <Tab label="試卷列表" />
          <Tab label="測驗列表" />
        </UiMatTabs>
      )}
      {tabIndex === 0 ? (
        /* 試卷列表 */
        <UiMainScreenBox>
          <UiActionsBox>
            {(isEnabledOrganizationSharedPapersSetting || isEnabledPaperPackageSetting) && (
              <UiflexBox>
                <UiToggleButtonGroup value={examPaperMode} onChange={changeExamPaperMode} exclusive>
                  <ToggleButton value="proprietary">
                    <span>自有試卷</span>
                  </ToggleButton>
                  {isEnabledOrganizationSharedPapersSetting && (
                    <ToggleButton value="organization">
                      <span>機構試卷</span>
                    </ToggleButton>
                  )}
                  {isEnabledPaperPackageSetting && (
                    <ToggleButton value="package">
                      <span>試卷包</span>
                    </ToggleButton>
                  )}
                </UiToggleButtonGroup>
              </UiflexBox>
            )}
            <UiflexBox>
              <MatTextField
                variant="outlined"
                value={searchValue}
                placeholder={placeholder}
                onChange={onSearchChange}
                onKeyPress={onSearchKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment onClick={clickSearchIcon} style={{ cursor: 'pointer' }}>
                      <Search style={{ color: '#919EAB', fontSize: '24px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </UiflexBox>
          </UiActionsBox>
          <UiHomeExamPaperBox>
            {
              {
                proprietary: (
                  <HomeExamPaperProprietary filterData={filterData} onCreateExam={onCreateExam} onPrompt={onPrompt} />
                ),
                organization: (
                  <HomeExamPaperOrganization filterData={filterData} onCreateExam={onCreateExam} onPrompt={onPrompt} />
                ),
                package: (
                  <HomeExamPaperPackage filterData={filterData} onCreateExam={onCreateExam} onPrompt={onPrompt} />
                ),
              }[examPaperMode]
            }
          </UiHomeExamPaperBox>
        </UiMainScreenBox>
      ) : (
        /* 測驗列表 */
        <HomeOnlineExamTableV2 setExams={setExams} />
      )}

      <BsModal
        open={isPromptModal}
        title="警告"
        isFull={true}
        cancelDisplay={false}
        onOk={onPrompt}
        onCancel={onPrompt}
      >
        此班級內沒有學生
      </BsModal>

      {isOpenModal && (
        <ExamModal
          isOpen={isOpenModal}
          onClick={createExam}
          onClose={closeModal}
          isLoading={isLoading}
          onChange={onModalChange}
          startAt={startAt}
          examName={examName}
          examPeriod={examPeriod}
          examTeachers={examTeachers}
          classNumber={classNumber}
          setFormData={setFormData}
          formData={formData}
          paperId={paperId}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          gradeSetting={gradeSetting}
          setGradeSetting={setGradeSetting}
        />
      )}
    </UiHomeOnlineExam>
  );
};
